@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Maison Neue Book';
    src: url('../fonts/MaisonNeue-Book.otf');
}

@font-face {
    font-family: 'Maison Neue';
    font-style: 'medium';
    src: url('../fonts/MaisonNeue-Medium.otf');
}

@font-face {
    font-family: 'Maison Neue';
    font-style: 'bold';
    src: url('../fonts/MaisonNeue-Bold.otf');
}